import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Call = (props) => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      contactJson {
        phone
        email
        contactButtonLink
      }
    }
  `);

  const { phone, email, contactButtonLink } = data.contactJson;

  return (
    <div className="call">
      <div className="call-box-top">
        {phone && (
          <div className="call-phone">
            <strong>Phone: </strong>
            <a href={`tel:${phone}`}>{phone}</a>
          </div>
        )}
        {email && (
          <div className="call-email">
            <strong>Email: </strong>
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        )}
      </div>
      {props.showButton && (
        <div className="call-box-bottom">
          <a href={contactButtonLink} className="button">
            Kontakt
          </a>
        </div>
      )}
    </div>
  );
};

export default Call;
